import React from "react"
import newCourseService from "../services/course_service";
import AuthenticatedFullscreenLayout from "../components/layouts/authenticatedFullscreenLayout";
import NavbarInContext from "../components/navBarInContext";
import Step from "../components/course-browser/step";
const LessonCodesPage = () => (
    <AuthenticatedFullscreenLayout title="Lessons" section={newCourseService.sections.lessons}>
        <Step/>
    </AuthenticatedFullscreenLayout>
)

export default LessonCodesPage
